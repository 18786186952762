import { combineReducers } from 'redux'
import settings from './settings.reducer'
import removeRequestSent from './removeRequestSent.reducer'
import { TProfileStore } from '../../../types';

export default combineReducers<TProfileStore>({
  settings,
  removeRequestSent
})

