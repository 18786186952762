import { call, put, takeEvery } from 'redux-saga/effects'
import { api, apiAuth } from '../../config/api'
import { t } from 'i18n-js'
import { notificationActions, profileActions } from '../actions'
import { TSaga } from '#/types';

function* watchUpdate({ payload }: ReturnType<typeof profileActions.update>): TSaga {
  const { data } = yield call(api.post, '/profile', payload)
  yield put(profileActions.updateSuccess(data.user))
  yield put(notificationActions.open({
    type: 'success',
    content: t('updated'),
  }))
}

function* watchRemove(): TSaga {
  yield call(apiAuth.delete, '/profile')
  yield put(profileActions.removeRequestSent())
}

export function* profileSagas(): TSaga {
  yield takeEvery(profileActions.UPDATE, watchUpdate)
  yield takeEvery(profileActions.REMOVE, watchRemove)
}