import i18n from 'i18n-js';
import axios, { AxiosRequestConfig } from 'axios'
import store from '#/store';
import { notificationActions } from '#/store/actions';

export const STORAGE_TOKEN_KEY = 'auth_token'

/**
 * Response might contain error & message items, let's track them
 * @param {String} data
 */
function trackResponseMessage(data: any) {
  if (data.message) {
    store.dispatch(notificationActions.open({
      type: data.error ? 'error' : 'info',
      content: i18n.t('serverMessages.' + data.message)
    }))
  }
  return data
}

const getEnv = (key: string): string => {
  const fullKey = 'REACT_APP_' + key;
  if (!(fullKey in process.env)) {
    throw new Error(`No key '${fullKey}' found in env`);
  }
  return process.env[fullKey] as string;
}

const tryToParseJson = (data: any) => {
  try {
    return JSON.parse(data);
  } catch(e) {
    // ok, not json
  }
  return data;
}
const opts = (path: string): AxiosRequestConfig =>  {
  const headers = {
    accept: 'application/json',
    'x-requested-with': 'XMLHttpRequest'
  };
  return {
    baseURL: getEnv('API_URL') + path,
    headers,
    transformResponse: [tryToParseJson, trackResponseMessage],
    // validateStatus: () => true,
  }
};

export const api = axios.create(opts('app'));
export const apiAuth = axios.create(opts('auth'));