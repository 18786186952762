
const getCrudUrls = (base: string) => ({
  ADD: `${base}/add`,
  EDIT: `${base}/edit/:id`,
  ITEM: `${base}/:id`,
  LIST: base,
})

const wishRoutes = getCrudUrls('/wishes')

export const COMPONENT_URLS = {
  CONFIRM_LOGIN: '/actions/confirm/:id/:token',
  FORGOT_PASS: '/forgot-pass',
  FORGOT_PASS_LOGIN: '/actions/passwordless/:id/:token',
  FRIENDS: '/friends/:tab',
  HOME: '/',
  LOGIN: '/login',
  LOGIN_CALLBACK: '/login/callback',
  PROFILE: '/profile/:tab',
  SIGN_UP: '/sign-up',
  SIGN_UP_SUCCESS: '/sign-up-success',
  UPCOMING_EVENTS: '/upcoming-events',
  WISHES: {
    ...wishRoutes,
    MY: wishRoutes.LIST + '?is_presented=0',
    OF_USER: '/wishes-of/:userId',
  }
}

export const THROTTLE_TIMEOUT = 900

export const DATE_FORMAT = 'YYYY/MM/DD'

export const DEFAULT_DEBOUNCE_ON_TYPE = 700

export const REDIRECT_URI_STORAGE_KEY = 'redirectUri';