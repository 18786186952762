import { RemoveConfirm } from '#/components/atoms';
import { TProfile, TProfileEntity } from '../../types';

const actionTypes = {
  UPDATE: 'PROFILE/UPDATE',
  UPDATE_SUCCESS: 'PROFILE/UPDATE_SUCCESS',
  REMOVE: 'PROFILE/REMOVE',
  REMOVE_REQUEST_SENT: 'PROFILE/REMOVE_REQUEST_SENT',
  REMOVE_CONFIRM: 'PROFILE/REMOVE_CONFIRM',
}

export const profileActions = {
  ...actionTypes,
  update: (payload: TProfile) => ({
    payload,
    type: actionTypes.UPDATE
  }),
  updateSuccess: (payload: TProfileEntity) => ({
    payload,
    type: actionTypes.UPDATE_SUCCESS
  }),
  remove: () => ({
    type: actionTypes.REMOVE
  }),
  removeRequestSent: () => ({
    type: actionTypes.REMOVE_REQUEST_SENT
  }),
  removeConfirm: (payload: string) => ({
    payload,
    type: actionTypes.REMOVE_CONFIRM
  }),
}